<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> {{ getTitle }}</el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" id="Scrollbar">
      <div class="fullScreenMainCon">
        <el-form ref="form" :model="form" :rules="rules" style="margin: 20px 0">
          <el-descriptions
            direction="vertical"
            :column="numberShow || form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 6 : 5"
            border
            style="width: 100%"
          >
            <el-descriptions-item
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
              label="单据编号"
              v-if="numberShow || form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            >
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
              label="申请人"
            >
              <el-form-item prop="createName" ref="createName">
                {{ form.createName }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
              label="费用所属部门"
            >
              <el-form-item prop="deptId" ref="deptId">
                <template v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'">
                  {{ form.deptName }}
                </template>
                <template v-else>
                  <el-select
                    v-if="![2, 3, 4].includes(options.list_s)"
                    v-model="form.deptId"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in deptList"
                      :key="item.id"
                      :label="item.deptName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <span v-else> {{ form.deptName }}</span></template
                >
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              label="领款人"
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
            >
              <el-form-item prop="payeeName" ref="payeeName">
                <template v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'">
                  {{ form.payeeName }}
                </template>
                <template v-else>
                  <SelectDialog
                    v-if="![2, 3, 4].includes(options.list_s)"
                    @selectDataChange="selectDataChange"
                  >
                    <el-input
                      slot="button"
                      :readonly="true"
                      style="cursor: pointer"
                      v-model="form.payeeName"
                      placeholder="请选择领款人"
                    ></el-input>
                  </SelectDialog>
                  <!-- <el-input
                    v-if="![2, 3, 4].includes(options.list_s)"
                    v-model="form.payeeName"
                    placeholder="请选择领款人"
                    @focus="payee_show = true">
                    <el-button
                      v-if="![2, 3, 4].includes(options.list_s)"
                      slot="append"
                      icon="el-icon-user"
                      @click="payee_show = true"></el-button>
                  </el-input> -->
                  <span v-else>{{ form.payeeName }}</span></template
                >
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
              label="申请日期"
            >
              <el-form-item prop="taskDate" ref="taskDate">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              label="费用所属单位"
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
            >
              <el-form-item prop="companyType" ref="companyType">
                <template v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'">
                  {{ form.companyType | dict(companyTypeList) }}
                </template>
                <template v-else>
                  <Dictionary
                    v-if="![2, 3, 4].includes(options.list_s)"
                    v-model="form.companyType"
                    :clearable="false"
                    code="COMPANY_TYPE"
                    placeholder="请选择费用所属单位"
                  />
                  <span v-else>
                    {{ getCompanyTypeName(form.companyType) }}
                  </span></template
                >
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource spendingLabelClassName"
              content-class-name="businessOutSource spendingContentClassName"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 17px">公共费用报销</span>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
              :span="1"
              v-if="options.list_s != 0"
              label="是否优先发放"
            >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="1"
                >是</el-radio
              >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="0"
                >否</el-radio
              >
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
              label="付款方式"
              :span="numberShow || form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 2 : 1"
            >
              <el-form-item prop="applyExpenseType" ref="applyExpenseType">
                <template v-if="![2, 3, 4].includes(options.list_s)">
                  <el-radio-group
                    style="margin-right: 8px"
                    v-model="form.applyExpenseType"
                    @input="applyExpenseTypeFn"
                  >
                    <template v-for="item in applyExpenseType">
                      <el-radio :label="item.dictVal" :key="item.id">{{ item.dictName }}</el-radio>
                    </template>
                  </el-radio-group>
                  <el-button
                    v-if="form.applyExpenseType == 'ADVANCE_PAYMENT'"
                    size="mini"
                    style="width: 115px"
                    type="warning"
                    @click="isFunds = true"
                  >
                    继续添加预支单
                  </el-button>
                  <span v-else style="width: 115px; display: inline-block; height: 10px"></span>
                </template>

                <span v-else>
                  {{ form.applyExpenseType | dict(applyExpenseType) }}
                </span>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="spendingLabelClassName"
              contentClassName="spendingContentClassName"
              label="报销方式"
              :span="3"
            >
              <el-form-item prop="expenseMode" ref="expenseMode">
                <el-radio-group
                  v-if="![2, 3, 4].includes(options.list_s)"
                  :disabled="form.id ? true : false"
                  v-model="form.expenseMode"
                  @input="expenseModeChange"
                >
                  <el-radio label="SELF_FILING">直接报销</el-radio>
                  <!-- <el-radio label="APPLY_DOCUMENT">预申请单</el-radio> -->
                  <el-radio label="REGISTRATION_EXPENDITURE_DOCUMENT">由登记单转为报销 </el-radio>
                </el-radio-group>
                <span v-else>
                  {{ form.expenseMode == 'SELF_FILING' ? '直接报销' : '由登记单转为报销' }}
                </span>
                <el-button
                  v-show="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
                  size="medium"
                  type="warning"
                  style="margin-left: 10%; padding: 5px"
                  @click="registrationIsShowFn"
                  >原登记单</el-button
                >
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
        <!-- 经费预支单据 -->
        <el-table
          v-if="form.counteractExpenseList && form.counteractExpenseList.length > 0"
          :data="form.counteractExpenseList"
          border
          id="table"
          style="margin-bottom: 10px"
          :row-style="{ height: '80px' }"
        >
          <!-- 删除 -->
          <el-table-column label="删除" v-if="![2, 3, 4].includes(options.list_s)" width="70">
            <template slot-scope="scope">
              <i
                class="el-icon-remove-outline iconCost"
                @click="expenditureRemove(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="expenseNumber"
            width="160"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            width="170"
            :render-header="addRedStar"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cost" label="预支总金额">
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sumNotCounteractCost">
            <template slot="header" slot-scope="scope">
              <div>未冲销金额</div>
              <div style="color: red">（包括审批中的金额）</div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="counteractCost"
            align="center"
            label="冲销金额"
            :render-header="addRedStar"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <template v-if="![2, 3, 4].includes(options.list_s)">
                <el-input
                  @blur="expenditureBlur(scope.row)"
                  type="number"
                  class="numrule"
                  placeholder="请输入"
                  v-model="scope.row.counteractCost"
                >
                </el-input>
                <div v-show="scope.row.isWarning" style="color: red">
                  冲销金额不能大于未冲销金额
                </div>
                <div v-show="!Number(scope.row.counteractCost)" style="color: red">金额不能为0</div>
              </template>
              <span v-else>{{ scope.row.counteractCost | applyAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 添加明细按钮 -->
        <div style="height: 40px; margin: 15px 0px">
          <div v-if="![2, 3, 4].includes(options.list_s)">
            <el-button v-if="isAddButtonShow" type="primary" @click="addRow(1)">
              添加空白明细
            </el-button>
            <el-button v-if="isAddButtonShow" type="primary" plain @click="addRow(2)">
              复制上一条科目
            </el-button>
            <el-button v-else type="info" class="addButton" @click="isSelectApplyDialogShow = true">
              选择申请单
            </el-button>
          </div>
          <el-button
            style="float: right"
            type="success"
            v-if="form.status == 10 || form.status == 100"
            @click="exportFileZipFn"
            :loading="loading"
          >
            全部附件下载
          </el-button>
        </div>

        <el-form class="column3 form_box table" label-position="top" style="width: 100%">
          <el-table
            :data="form.detailList"
            :key="tableIndex"
            :header-cell-class-name="headerInvoiceClassName"
            id="table"
            :row-style="{ height: '80px' }"
          >
            <!-- 删除 -->
            <el-table-column label="操作" width="50">
              <template slot-scope="scope">
                <template v-if="![2, 3, 4].includes(options.list_s) && isAddButtonShow">
                  <i
                    v-show="scope.row.notOperable != 'NOT_OPERABLE'"
                    class="el-icon-remove-outline iconCostDelete"
                    @click="handleRemove(scope.$index, scope.row)"
                  ></i>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              label="日期"
              width="160"
              :render-header="addRedStar"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <el-date-picker
                  :disabled="
                    [2, 3, 4].includes(options.list_s) || scope.row.notOperable == 'NOT_OPERABLE'
                  "
                  v-model="scope.row.taskDate"
                  type="date"
                  value-format="timestamp"
                  :picker-options="pickerOptions"
                  placeholder="选择日期"
                >
                </el-date-picker>
                <div class="color_r" v-if="!scope.row.taskDate && status == 1">日期必填</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="typeOne"
              width="160"
              label="费用科目一"
              :show-overflow-tooltip="false"
            >
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> 费用科目一
                <div>
                  <el-button
                    size="mini"
                    type="warning"
                    :loading="typeDialogLoading"
                    @click="isDialogShow = true"
                    >科目说明</el-button
                  >
                </div>
              </template>
              <template slot-scope="scope">
                <Dictionary
                  :filterable="true"
                  :clearable="false"
                  :disabled="[2, 3, 4].includes(options.list_s)"
                  v-model="scope.row.typeOne"
                  code="CBFYZC"
                  @change="valueChange($event, scope.row, 1)"
                  :placeholder="[2, 3, 4].includes(options.list_s) ? '' : '请选择'"
                />
                <div class="color_r" v-if="!scope.row.typeOne && status == 1">费用科目一必填</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              label="费用科目二"
              width="160"
              :render-header="addRedStar"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary
                  :disabled="[2, 3, 4].includes(options.list_s)"
                  v-model="scope.row.typeTwo"
                  :code="scope.row.typeOne"
                  :clearable="false"
                  :disabledSelect="true"
                  :placeholder="[2, 3, 4].includes(options.list_s) ? '' : '请选择'"
                  @change="valueChange($event, scope.row, 2)"
                  @optionsChange="requiredTwo($event, scope.row, 2)"
                />
                <div
                  class="color_r"
                  v-show="
                    (scope.row.rulesTips && !scope.row.typeTwo) ||
                    (scope.row.rulesTips && rulesTips == 1 && !scope.row.typeTwo)
                  "
                >
                  费用科目二有值必填
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              label="费用科目三"
              width="160"
              :render-header="addRedStar"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary
                  :disabled="[2, 3, 4].includes(options.list_s)"
                  v-model="scope.row.typeThree"
                  :code="scope.row.typeTwo"
                  :clearable="false"
                  :placeholder="[2, 3, 4].includes(options.list_s) ? '' : '请选择'"
                  @change="valueChange($event, scope.row, 3)"
                  @optionsChange="requiredTwo($event, scope.row, 3)"
                />
                <div
                  class="color_r"
                  v-show="
                    (scope.row.rulesTips2 && !scope.row.typeThree) ||
                    (scope.row.rulesTips2 && rulesTips2 == 1 && !scope.row.typeThree)
                  "
                >
                  费用科目三有值必填
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="cost"
              label="金额 (元)"
              width="120"
              :render-header="addRedStar"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <el-input
                  v-if="![2, 3, 4].includes(options.list_s)"
                  @blur="blurInput(scope.$index)"
                  type="number"
                  class="numrule"
                  placeholder="请输入"
                  v-model="scope.row.cost"
                >
                </el-input>
                <span v-else>{{ scope.row.cost | applyAmount }}</span>
                <div class="color_r" v-if="scope.row.cost <= 0 && status == 1">金额不能为零</div>
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="contentDetail"
              :label="'事由附件详细说明'"
              width="250"
              :render-header="addRedStar"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <el-input
                  v-if="![2, 3, 4].includes(options.list_s)"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 5 }"
                  placeholder="请输入"
                  v-model="scope.row.contentDetail"
                ></el-input>
                <span v-else
                  ><el-input
                    :disabled="!form.isDisplayEditButton"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 999 }"
                    v-model="scope.row.contentDetail"
                  ></el-input
                ></span>
                <div class="color_r" v-if="!scope.row.contentDetail && status == 1">
                  事由附件详细说明必填
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :render-header="addRedStar"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
                  <el-date-picker
                    :disabled="[2, 3, 4].includes(options.list_s)"
                    v-model="scope.row.estimatedRefundDate"
                    type="date"
                    value-format="timestamp"
                    placeholder="选择预计退回时间"
                  >
                  </el-date-picker>
                  <div class="color_r" v-if="!scope.row.estimatedRefundDate && status == 1">
                    日期必填
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              min-width="260"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div class="upload-center">
                  <UploadFileToinvoice
                    v-show="![2, 3, 4].includes(options.list_s)"
                    :type="type"
                    :fields="fields"
                    @click.native="uploadIndex(scope.$index)"
                    @uploadFileToinvoice="invoiceUpload"
                  ></UploadFileToinvoice>
                </div>
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                        <!-- <span style="color: red" v-else>编码：{{ n.invoiceNo }}</span> -->
                      </div>
                    </div>

                    <div>
                      <template v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'">
                        <i
                          @click="del(index, scope.$index, n)"
                          v-if="
                            (n.notOperable != 'NOT_OPERABLE' && options.list_s == 1) ||
                            (n.notOperable != 'NOT_OPERABLE' && options.list_s == 0)
                          "
                          class="iconfont iconerror iconCost"
                        ></i>
                      </template>
                      <template v-else>
                        <i
                          @click="del(index, scope.$index, n)"
                          v-show="![2, 3, 4].includes(options.list_s)"
                          class="iconfont iconerror iconCost"
                        ></i>
                      </template></div
                  ></template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="252"
              label="非发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <div class="upload-center">
                  <Upload
                    v-show="![2, 3, 4].includes(options.list_s)"
                    :type="type"
                    :fields="fields"
                    @click.native="uploadIndex(scope.$index)"
                    @upload="upload"
                  ></Upload>
                </div>
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                    <template v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'">
                      <i
                        @click="del(index, scope.$index, n)"
                        v-if="
                          (n.notOperable != 'NOT_OPERABLE' && options.list_s == 1) ||
                          (n.notOperable != 'NOT_OPERABLE' && options.list_s == 0)
                        "
                        class="iconfont iconerror iconCost"
                      ></i>
                    </template>
                    <template v-else>
                      <i
                        @click="del(index, scope.$index, n)"
                        v-show="![2, 3, 4].includes(options.list_s)"
                        class="iconfont iconerror iconCost"
                      ></i></template
                  ></template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>

        <!-- 合计内容 -->
        <el-descriptions title="" :column="4" border>
          <el-descriptions-item
            label="原登记单总金额"
            v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            labelStyle="width:120px"
          >
            {{ form.registrationExpenditureTotalCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="报销合计"
            :span="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
            labelStyle="width:120px"
          >
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="金额大写" labelStyle="width:120px">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
          <template v-if="isApplyExpenseType && form.applyExpenseType == 'ADVANCE_PAYMENT'">
            <template v-if="form.status != 100">
              <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                {{ totalCounteract.sumAdvanceCost | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item labelStyle="width:150px">
                <template slot="label">
                  <div>　冲销中的金额</div>
                  <div>（包括本单的冲销）</div>
                </template>

                {{ writeOffMoney | applyAmount }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item
              :span="form.status != 100 ? 1 : 2"
              label="本次冲销总金额"
              labelStyle="width:120px"
            >
              {{ thisTimeMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="form.status != 100 ? 1 : 2"
              label="报销支付金额"
              labelStyle="width:120px"
            >
              <template v-if="paymentMony">
                {{ paymentMony | applyAmount }}
              </template>
              <template v-else> 0 </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>
        <div
          v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' && registrationShow"
          style="color: red"
        >
          请注意: 当前总金额已超过原登记单总金额 10%,审批流程将会重新走报销流程
        </div>
        <el-descriptions
          :column="1"
          direction="vertical"
          style="margin-top: 10px"
          border
          v-if="form.remark"
        >
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
            </template>
            {{ form.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
            </template>
            <template v-for="item in payedFileList">
              <a
                :key="item.id"
                target="_blank"
                style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                :href="`${filepath}${item.filePath}`"
                >{{ item.fileName }}</a
              >
            </template>
          </el-descriptions-item>
        </el-descriptions>

        <div v-if="bidIsShow" style="margin: 36px 0">
          <div>
            <span style="color: red; font-size: 18px; margin-right: 5px">*</span>
            <span style="color: #000; font-size: 18px; margin: 5px 10px 0 0; font-weight: 700"
              >投标信息</span
            >
            <el-button
              type="success"
              v-if="![2, 3, 4].includes(options.list_s)"
              @click="bidDialog = true"
            >
              选择关联投标信息
            </el-button>
          </div>
          <el-table :data="bidTable" border max-height="300">
            <el-table-column
              align="center"
              prop="bidName"
              min-width="280"
              :show-overflow-tooltip="false"
              label="标的名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="bidType"
              width="100"
              :show-overflow-tooltip="false"
              label="标的类型"
            >
              <template slot-scope="scope">
                {{ scope.row.bidType | dict(bidType) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              width="70"
              :show-overflow-tooltip="false"
              prop="bidHostEscort"
              label="是否重点关注"
            >
              <template slot-scope="scope">
                {{ scope.row.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="155"
              sortable
              prop="bidOpeningTime"
              label="开标时间"
            >
              <template slot-scope="scope">
                {{ scope.row.bidOpeningTime | timeFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="160"
              prop="bidOwnerUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="bidAgency"
              label="代理\招标单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="160"
              :show-overflow-tooltip="false"
              prop="bidWinningAmount"
              label="中标金额(元)"
            >
              <template slot-scope="scope">
                {{ scope.row.bidWinningAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="bidStatus"
              label="状态"
              width="110"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.bidStatus | dict(bidStatus) }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              :show-overflow-tooltip="false"
              align="center"
              fixed="right"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="options.list_s == 4 && permission(['BAIL_UPDATE'])"
                  style="background-color: #7c9cec; color: #fff"
                  size="mini"
                  @click="onBidEditFn"
                  >保证金修改</el-button
                >
                <el-button
                  type="primary"
                  v-if="![2, 3, 4].includes(options.list_s)"
                  size="mini"
                  @click="bidDialog = true"
                  >更换</el-button
                >
                <el-button
                  type="danger"
                  v-if="![2, 3, 4].includes(options.list_s)"
                  size="mini"
                  @click="deleteBid"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 意见区 -->
        <OpinionArea
          ref="opinionArea"
          :title="'报销进度'"
          :procInstId="this.options.processInstId"
          :instInvolved="instInvolved"
        ></OpinionArea>
      </div>

      <!-- 按钮区域 -->
      <!-- 填报详情按钮 -->
      <div class="fullScreenOperation" v-if="detailType == 0">
        <el-button
          v-if="![2, 3, 4].includes(options.list_s) && form.status != 10"
          type="success"
          class="teal_bg"
          :loading="loading"
          @click="save"
        >
          提交
        </el-button>
        <el-button
          v-if="![2, 3, 4].includes(options.list_s) && form.status != 10"
          type="success"
          :loading="loading"
          @click="tempSave"
        >
          {{ options.id ? '保存' : '暂存' }}
        </el-button>
        <el-button
          v-if="form.isDisplayEditButton"
          type="warning"
          :loading="loading"
          @click="tempSave"
        >
          编辑
        </el-button>
        <el-button
          v-if="
            form.status == '10' &&
            !hideRejectButton &&
            options.export != 1 &&
            form.taskDefinitionKey != 'CASHIER'
          "
          type="success"
          :loading="loading"
          @click="reject"
        >
          撤回
        </el-button>
        <el-button
          type="primary"
          @click="affirmCost(options.id)"
          v-if="options.passText == '发放' && options.status != 100"
          >确认报销</el-button
        >
        <el-button
          type="danger"
          v-if="options.passText == '发放' && options.status != 100"
          :loading="loading"
          @click="showDialogForm(2)"
        >
          不通过
        </el-button>
        <el-button
          type="warning"
          v-if="
            options.list_s == 3 || options.list_s == 4 || options.export == 1 || form.status > 5
          "
          @click="printFn"
          >预览打印</el-button
        >
        <!-- v-if="options.list_s == 3 && showExportButton" -->
        <el-button
          type="success"
          v-if="
            options.list_s == 3 || options.list_s == 4 || options.export == 1 || form.status > 5
          "
          :loading="loading"
          @click="exporting"
        >
          导出
        </el-button>
        <SelectDialog
          v-if="options.list_s == 4 && form.status == 10 && permission(['ADD_PUBLIC_AGENT'])"
          @selectDataChange="selectAddAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary" :loading="loading"> 增加待办人 </el-button>
        </SelectDialog>
        <el-button type="info" plain @click="re">返回</el-button>
      </div>
      <!-- 审批详情按钮 -->
      <div class="fullScreenOperation" v-else-if="detailType == 1">
        <el-button
          v-if="form.isDisplayEditButton"
          type="warning"
          :loading="loading"
          @click="tempSave"
        >
          编辑
        </el-button>
        <el-button type="success" :loading="loading" @click="showDialogForm(1)"> 通过 </el-button>
        <el-button type="danger" :loading="loading" @click="showDialogForm(2)"> 不通过 </el-button>
        <SelectDialog
          v-if="form.status == 10 && permission(['FLOW_HAND_OVER'])"
          @selectDataChange="selectAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary" :loading="loading"> 转审 </el-button>
        </SelectDialog>
        <el-button type="info" @click="re">返回</el-button>
      </div>

      <!-- 只有返回 -->
      <div class="fullScreenOperation" v-else>
        <el-button
          v-if="form.isDisplayEditButton"
          type="warning"
          :loading="loading"
          @click="tempSave"
        >
          编辑
        </el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <!-- 选择报销申请单 -->
    <EditDialog width="65%" :isShow.sync="isSelectApplyDialogShow">
      <div slot="title" class="title">选择报销申请单</div>
      <div slot="content" class="content">
        <!-- 报销申请单列表 -->
        <el-table
          :data="applyData"
          style="width: 100%"
          height="400"
          stripe
          border
          @selection-change="handleSelectionChange"
        >
          <!-- ref="applySelectTable" -->
          <el-table-column type="selection" label="选择" width="60"> </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="expand-block">
                <div class="expand-title">申请明细</div>
                <table class="expand-table">
                  <thead>
                    <td style="width: 100px">明细日期</td>
                    <td style="width: 100px">明细金额</td>
                    <td>事项说明</td>
                  </thead>
                  <tr v-for="(item, index) in props.row.detail" :key="index">
                    <td style="width: 100px">{{ item.taskDate }}</td>
                    <td style="width: 100px">{{ item.cost }}</td>
                    <td>{{ item.contentDetail }}</td>
                  </tr>
                </table>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="申请单编号"> </el-table-column>
          <el-table-column prop="taskDate" label="申请时间"> </el-table-column>
          <el-table-column prop="cost" label="申请金额"> </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" :loading="loading" @click="applySelectSubmit">选择</el-button>
        <el-button type="warning" plain @click="isSelectApplyDialogShow = false">取消</el-button>
      </div>
    </EditDialog>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div slot="title" style="color: #409eff">
        {{ dg_form.status == 1 ? '审批通过意见' : '审批不通过意见' }}
        <div
          v-if="isInvoiceUsed"
          style="
            display: flex;
            justify-content: center;
            font-weight: 900;
            font-size: 20px;
            color: red;
          "
        >
          <span>此发票号码识别存疑，请核查</span>
        </div>
      </div>
      <el-form :model="dg_form" ref="dg_formRef" :rules="dg_formRules">
        <el-form-item prop="" v-if="dg_form.status == 1">
          <el-input
            @change="$forceUpdate()"
            v-model.trim="dg_form.opinion"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="opinion" v-else>
          <el-input
            @change="$forceUpdate()"
            v-model.trim="dg_form.opinion"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          :type="dg_form.status == 1 ? 'success' : 'danger'"
          style="width: 120px"
          @click="addApprove"
          >{{ dg_form.status == 1 ? '审批通过' : '审批不通过' }}</el-button
        >
        <el-button
          type="warning"
          style="margin-right: 37%; margin-left: 12%; width: 120px"
          plain
          @click="handleClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <!-- 财务确认报销弹出框 -->
    <EditDialog :isShow.sync="passDialogFormVisible" @submit="passSubmit">
      <div slot="title" class="title">确认报销</div>
      <div slot="content" class="content">
        <div
          v-if="isInvoiceUsed"
          style="
            display: flex;
            justify-content: center;
            font-weight: 900;
            font-size: 20px;
            color: red;
          "
        >
          <span>此发票号码识别存疑，请核查</span>
        </div>
        <el-form :model="pass_form" label-width="100px">
          <el-form-item label="附件" prop="remark">
            <div class="iconCost">
              <Upload :type="type" :fields="fields" @upload="passUpload"></Upload>
            </div>

            <div
              v-for="(n, index) in pass_form.affirmCostFileList"
              :key="index"
              style="margin-top: 5px"
            >
              <a
                class="fj-text"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>

              <i @click="passDel(index)" class="iconfont iconerror iconCost"></i>
            </div>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="pass_form.remark" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>

    <EditDialog width="60%" ref="IframeDlg" :isShow.sync="printDiaLog">
      <div slot="content" class="content">
        <div id="printArea">
          <template v-for="(n, i) in innerArr">
            <div :key="i">
              <div class="outer-table">{{ newDate | completeTime }}</div>
              <table border="1">
                <thead>
                  <tr>
                    <th
                      colspan="3"
                      style="
                        font-size: 20px;
                        border-right: 0px;
                        text-align: left;
                        padding-left: 5px;
                      "
                    >
                      公共费用报销单
                    </th>
                    <th colspan="5" style="font-size: 20px; border-left: 0px; border-right: 0px">
                      {{ n.title }}
                    </th>
                    <th colspan="4" style="border-left: 0px; text-align: right; padding-right: 5px">
                      <span
                        >编号：<span> {{ form.expenseNumber }}</span></span
                      >
                    </th>
                  </tr>
                  <tr>
                    <th style="border: 0px; text-align: left" colspan="3">
                      <span>费用所属单位：</span
                      ><span style="font-size: 16px">
                        {{ getCompanyTypeName(form.companyType) }}</span
                      >
                    </th>
                    <th style="border: 0px; text-align: left" colspan="6">
                      <span>报销部门：</span
                      ><span
                        :style="
                          form.deptName
                            ? form.deptName.length < 12
                              ? 'font-size: 16px !important;'
                              : 'font-size: 0.75rem  !important;'
                            : 'font-size: 16px !important;'
                        "
                      >
                        {{ form.deptName }}</span
                      >
                    </th>
                    <th
                      style="border: 0px; border-right: 1px; text-align: right; padding-right: 5px"
                      colspan="3"
                    >
                      <span>领款人：</span><span style="font-size: 16px">{{ form.payeeName }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th><span>日期</span></th>
                    <th colspan="3"><span>科目明细</span></th>
                    <th>
                      <span>{{ n.money }}</span>
                    </th>
                    <th colspan="7"><span>事项说明</span></th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="detail" v-for="(item, index) in n.printData" :key="index + i">
                    <td>{{ item.taskDate | dateFormat }}</td>
                    <td colspan="3">
                      {{ item.typeOneName }}{{ item.typeTwoName ? '-' + item.typeTwoName : ''
                      }}{{ item.typeThreeName ? '-' + item.typeThreeName : '' }}
                    </td>
                    <td>{{ item.cost | applyAmount }}</td>
                    <td
                      colspan="7"
                      :style="
                        item.contentDetail
                          ? item.contentDetail.length < 60
                            ? 'font-size: 14px !important;'
                            : 'font-size: 12px!important;'
                          : 'font-size: 14px !important;'
                      "
                    >
                      {{ item.contentDetail }}
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td style="border: 0px; text-align: left; padding-left: 5px" colspan="2">
                      <span>总合计：</span
                      ><span style="font-size: 18px">{{ amount | amountToConvert }}</span>
                    </td>
                    <td
                      :colspan="form.applyExpenseType == 'ADVANCE_PAYMENT' ? '4' : '10'"
                      style="border: 0px"
                    >
                      <span>金额大写：</span
                      ><span style="font-size: 18px">{{ amount | numberParseChina }}</span>
                    </td>
                    <template v-if="form.applyExpenseType == 'ADVANCE_PAYMENT'">
                      <td colspan="2" style="border: 0px">
                        <span>冲销总金额:</span
                        ><span style="font-size: 18px">{{ thisTimeMoney | amountToConvert }}</span>
                      </td>
                      <td colspan="2" style="border: 0px">
                        <span>报销支付:</span
                        ><span style="font-size: 18px">{{ paymentMony | amountToConvert }}</span>
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <td v-for="(item, i) in examineList" :key="i" :colspan="2">
                      {{ item.step }}
                    </td>
                  </tr>
                  <tr>
                    <td v-for="(item, i) in examineList" :key="i" :colspan="2">
                      {{ item.auditUserName }}
                    </td>
                  </tr>
                  <tr style="height: 1px; border: 0px">
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                  </tr>
                  <tr>
                    <td style="border: 0px; text-align: left; padding-left: 5px" colspan="3">
                      <span>出纳：</span><span>{{ cashierName ? cashierName : '-' }}</span>
                    </td>
                    <td colspan="6" style="border: 0px">
                      <span>填报日期：</span
                      ><span style="font-size: 16px"> {{ form.taskDate | dateFormat }}</span>
                    </td>
                    <td style="border: 0px; text-align: right; padding-right: 5px" colspan="3">
                      <span>申请人：</span
                      ><span style="font-size: 16px">{{ form.createName }}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div class="outer-table">{{ i + 1 }}/{{ innerArr.length }}</div>
            </div>
          </template>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" v-print="print">打印</el-button>
        <el-button type="info" @click="printDiaLog = false">返回</el-button>
      </div>
    </EditDialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
    <el-dialog
      :visible.sync="registrationDialog"
      append-to-body
      width="65%"
      :before-close="cancellationFn"
    >
      <div slot="title" style="color: #409eff">登记单</div>
      <el-table
        v-loading="registrationLoading"
        :data="registrationData"
        style="width: 100%"
        max-height="400"
        border
        id="applySelectTable"
        ref="applySelectTable"
        @row-click="rowClickFn"
        @selection-change="handleCurrentChange"
      >
        <el-table-column type="selection" label="选择" width="60"> </el-table-column>
        <el-table-column prop="expenseNumber" width="160px" label="申请单编号"> </el-table-column>
        <el-table-column
          align="center"
          prop="expenseBelongName"
          :show-overflow-tooltip="false"
          label="所属部门"
        ></el-table-column>
        <el-table-column
          align="center"
          :show-overflow-tooltip="false"
          width="180"
          prop="registrationType"
          label="登记类型"
        >
          <template slot-scope="scope">
            {{ scope.row.registrationType | dict(registrationTypeList) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :show-overflow-tooltip="false"
          width="180"
          prop="companyType"
          label="申请单位"
        >
          <template slot-scope="scope">
            {{ scope.row.companyType | dict(companyTypeList) }}
          </template>
        </el-table-column>
        <el-table-column prop="taskDate" align="center" label="申请时间">
          <template slot-scope="scope">
            {{ scope.row.taskDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="sumCost" align="center" label="申请金额">
          <template slot-scope="scope">
            {{ scope.row.sumCost | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="success"
          style="min-width: 120px; margin: 0 25px"
          v-if="registrationData.length > 0"
          @click="registrationFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          @click="cancellationFn"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <el-dialog :visible.sync="registrationIsShow" append-to-body width="80%">
      <div slot="title" style="color: #409eff">登记单</div>
      <LogisticsRegistration :form="oldRegistrationInfo"></LogisticsRegistration>
    </el-dialog>
    <!-- <el-dialog width="70%" title="费用科目说明" append-to-body :visible.sync="subjectIsShow">
      <div class="imgDialog">
        <img :src="`${imgUrl}?${new Date().getTime()}`" alt="费用科目说明" width="100%" />
      </div>
    </el-dialog> -->

    <!-- 费用科目说明 -->
    <TypeDialog :isDialogShow.sync="isDialogShow" @changeLoading="handleChangeLoading"></TypeDialog>

    <!-- 经费预支弹窗 -->
    <ExpenditureAdvance
      v-if="isFunds"
      :pitchOnList="pitchOnList"
      @saveFn="expenditureFn"
      :isShow.sync="isFunds"
    ></ExpenditureAdvance>
    <ExpenditureAdvanceDetails
      :isShow.sync="expenditureIsShow"
      :options="expenditureOptions"
      @closeExpenditure="expenditureIsShow = false"
    ></ExpenditureAdvanceDetails>
    <!-- 选择投标 -->
    <SelectBidDialog
      v-if="bidDialog"
      :isShow.sync="bidDialog"
      @onSelectFn="onSelectFn"
      :bidSelectId="bidSelectId"
      @bidCancel="bidDialog = false"
    />
    <!-- 提交意见 -->
    <SubmitOpinionDialog
      v-if="submitDialog"
      :isShow.sync="submitDialog"
      :isPulic="isSaveTipsShow"
      @offsetCancel="submitDialog = false"
      @submitOpinionFn="submitOpinionFn"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    UploadFileToinvoice: () => import('@/components/upload/uploadFileToinvoice.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    LogisticsRegistration: () => import('@/views/publicView/LogisticsRegistration.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    TypeDialog: () => import('@/components/typeDialog.vue'),
    ExpenditureAdvance: () => import('@/components/funds/expenditure-advance.vue'),
    ExpenditureAdvanceDetails: () => import('@/components/funds/expenditure-advance-details.vue'),
    SubmitOpinionDialog: () => import('@/components/submit-opinion-dialog.vue'),
    SelectBidDialog: () => import('@/components/bid/selectBidDialog.vue'),
  },

  inject: ['re'],

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    getTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.$nextTick(() => {
            let scroll = document.getElementById('Scrollbar')
            if (scroll) {
              if (this.options.list_s == 3) {
                setTimeout(() => {
                  scroll.scrollTop = scroll.scrollHeight
                }, 200)
              } else {
                scroll.scrollTop = 0
              }
            }
          })
        }
      },
    },
    printDiaLog: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            let Styles = document.querySelectorAll('.detail')
            var userAgent = navigator.userAgent.toLowerCase()
            let str = userAgent.split('/')
            str = str[3].split(' ')
            str = str[0].split('.')
            Styles.forEach(v => {
              if (userAgent.includes('edg')) {
                v.style.setProperty('--height', '72px')
              } else if (userAgent.includes('chrome')) {
                if (str[0] < 110) {
                  v.style.setProperty('--height', '72px')
                } else {
                  v.style.setProperty('--height', '72px')
                }
              } else {
                v.style.setProperty('--height', '72px')
              }
            })
          })
        }
      },
    },

    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.init()
        }
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          newVal.forEach(v => {
            if (!this.isShowType(v)) {
              this.imgList.push(v)
            }
          })
        }
      },
    },
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),

    amount() {
      let num = 0
      if (this.form.detailList) {
        this.form.detailList.forEach(v => {
          num += Number(String(v.cost).replace(/,/gi, ''))
        })
      }
      num = num.toFixed(2)
      return num
    },

    numberShow() {
      if (this.options.list_s == 2 || this.options.list_s == 3 || this.options.list_s == 4) {
        return true
      } else {
        return false
      }
    },
    registrationShow() {
      let isShow = false
      let num = this.form.registrationExpenditureTotalCost * 1.1
      if (this.amount > num) {
        isShow = true
      } else {
        isShow = false
      }
      return isShow
    },
    // 冲销中的金额
    writeOffMoney() {
      let num = 0
      num = this.totalCounteract.sumCounteractCost + this.thisTimeMoney
      return num
    },
    // 本次冲销总金额
    thisTimeMoney() {
      let num = 0
      if (this.form.counteractExpenseList) {
        this.form.counteractExpenseList.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      return num
    },
    // 报销支付金额
    paymentMony() {
      let num = 0
      num = this.amount - this.thisTimeMoney
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
    // 检查明细中发票附件是否已存在使用过
    isInvoiceUsed() {
      if (this.form.detailList) {
        return this.form.detailList.some(v =>
          v.expenseFileList.some(
            item => item.fileType === 'INVOICE' && item.fileInvoiceNoType === 'EXIST'
          )
        )
      }
    },
    bidIsShow() {
      if (this.form.detailList) {
        const item = this.form.detailList.find(
          v =>
            v.typeOne === 'zhao_tou_biao_fei_yong' ||
            v.typeTwo === 'tou_bao_zheng_jin' ||
            v.typeTwo === 'lv_yue_bao_zheng_jin'
        )

        if (item) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    EstimatedShow() {
      if (this.form.detailList) {
        const item = this.form.detailList.find(
          v => v.typeTwo === 'tou_bao_zheng_jin' || v.typeTwo === 'lv_yue_bao_zheng_jin'
        )

        if (item) {
          return true
        } else {
          return false
        }
      }
      return false
    },
  },

  data() {
    return {
      printDiaLog: false,
      detailType: '', //* detailType标识详情类型，0表示填报详情，1表示审批详情
      hideRejectButton: false, //显示是否展示撤回按钮
      showExportButton: false, //导出按钮是否显示
      showEditDialog: false,
      loading: false,
      status: 0,
      form: {},
      fileDeleteList: [], //附件删除数组
      detailDeleteList: [], //删除明细数组
      rules: {
        companyType: [
          { required: true, message: '请选择费用所属单位', trigger: ['blur', 'change'] },
        ],
      },
      // tableData: [], //明细表格数据
      // 时间选择器
      pickerOptions: {
        disabledDate(time) {
          // 禁用以后的时间
          return time.getTime() > Date.now() - 8.64e6
        },
      },
      companyTypeList: [],
      now_index: '',
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      type: 'GGFYBX',
      rowDeleteList: [], //被删除的明细
      isSelectApplyDialogShow: false, //报销申请单选择弹框显示与隐藏
      isAddButtonShow: true,
      multipleSelection: [], //当前的选中的报销单
      rulesTips2: 0,
      rulesTips: 0,
      //报销申请单列表
      applyData: [],

      // 审批意见框弹出
      dialogFormVisible: false,
      dg_form: { opinion: '', status: 0 },
      dg_formRules: {
        opinion: [{ required: true, message: '请输入审批不通过意见', trigger: 'blur' }],
      },
      companyTypeList: [],
      passDialogFormVisible: false,
      payedFileList: [], //确认报销
      pass_form: {
        projectCostFileList: [],
        remark: '',
      },
      deptList: [], // 部门 列表
      dialogVisible: false,
      imgList: [],
      temporaryList: [],
      img_index: 0,
      print: {
        id: 'printArea',
        popTitle: '', // 打印配置页上方的标题
        type: 'html', //打印类型是html
        scanStyles: false,
        targetStyles: ['*'],
      },
      examineList: [],
      cashierName: '',
      innerArr: [],
      instInvolved: {}, //当前进度处理人数组
      registrationDialog: false,
      registrationLoading: false,
      registrationIsShow: false,
      registrationData: [],
      registrationTypeList: [],
      registrationInfo: {},
      oldRegistrationInfo: {},
      newDate: Date.now(),
      isDialogShow: false,
      typeDialogLoading: false,
      imgUrl: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/科目数据.png',
      isSaveTipsShow: false,
      isFunds: false, //预支弹窗
      applyExpenseType: [], //付款方式字典
      pitchOnList: [], //预支选中数组
      expenditureOptions: {}, //预支详情
      expenditureIsShow: false, //预支详情弹窗
      totalCounteract: {
        sumAdvanceCost: 0, //经费预支总合计
        sumCounteractCost: 0, //	冲销中金额合计
      },
      isApplyExpenseType: false, //冲抵额度
      tableIndex: 0, //明细刷新key
      submitDialog: false, //提交意见弹窗
      bidTable: [], //投标信息
      bidStatus: [], //投标状态
      bidType: [],
      bidSelectId: null,
      delBidManagementId: null,
      bidDialog: false,
    }
  },
  created() {
    this.getType()
  },
  mounted() {},

  methods: {
    // 删除
    deleteBid() {
      this.bidTable = []
    },
    // 保证金修改
    onBidEditFn() {
      this.$router.push({
        name: 'QBTBLB',
        params: { data: this.form.bidManagement },
      })
    },
    onSelectFn(row) {
      this.bidSelectId = row.id
      this.bidTable = [{ ...row }]
      this.bidDialog = false
    },
    headerInvoiceClassName({ row, column, rowIndex, columnIndex }) {
      if (column.label === '发票附件上传') {
        return ['headerInvoiceClassName']
      }
      return []
    },
    // 新增审核人
    selectAddAuditor(row) {
      this.$api.addAuditor
        .addTaskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 转审
    selectAuditor(row) {
      this.$api.addAuditor
        .taskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 经费预支-详情
    expenditureDetailFn(row) {
      this.expenditureOptions = { ...row, id: row.advanceId }
      this.expenditureIsShow = true
    },
    //  经费预支-金额失焦
    expenditureBlur(row) {
      this.form.counteractExpenseList.forEach(v => {
        if (v.advanceId === row.advanceId) {
          if (Number(v.counteractCost) < 0) {
            v.counteractCost = 0
          }
          v.counteractCost = Number(v.counteractCost).toFixed(2)
          if (v.counteractCost > row.sumNotCounteractCost) {
            v.isWarning = true
          } else {
            v.isWarning = false
          }
        }
      })
    },
    // 经费预支-删除行
    expenditureRemove(index, row) {
      this.form.counteractExpenseList.splice(index, 1)
      this.pitchOnList.splice(index, 1)
      if (row.id) {
        this.form.counteractDeleteList.push(row.id)
      }
    },
    // 选中值
    expenditureFn(arr) {
      arr.forEach(v => {
        if (!this.pitchOnList.find(item => item.id === v.id)) {
          this.form.counteractExpenseList.push({
            advanceId: v.id,
            expenseNumber: v.expenseNumber,
            taskDate: v.taskDate,
            cost: v.cost,
            sumNotCounteractCost: v.sumNotCounteractCost,
            counteractCost: null,
            isWarning: false,
          })
          this.pitchOnList.push(v)
        }
      })
    },
    // 付款方式
    applyExpenseTypeFn() {
      this.pitchOnList = []
      if (!this.options.id && this.form.applyExpenseType === 'ADVANCE_PAYMENT') {
        this.$api.funds
          .totalCounteract()
          .then(res => {
            if (res.data) {
              this.totalCounteract = res.data
            }
            if (!res.data || !res.data.sumAdvanceCost || res.data.sumAdvanceCost <= 0) {
              this.$message.warning('您当前没有预支费用，无需冲还预付款')
              this.form.applyExpenseType = 'REGULAR_PAYMENT_REQUEST'
              return
            }
            this.isApplyExpenseType = true
            this.isFunds = true
          })
          .catch(err => {
            console.log(err)
            this.isApplyExpenseType = false
          })
      } else {
        this.isFunds = false
        this.isApplyExpenseType = false
        this.form.counteractExpenseList = []
        this.form.counteractDeleteList = []
      }
    },
    blurInput(index) {
      this.form.detailList.forEach((v, i) => {
        if (Number(v.cost) < 0) {
          v.cost = 0
        }
        if (i === index) {
          v.cost = Number(v.cost).toFixed(2)
        }
      })
    },
    async exportFileZipFn() {
      let execute = false
      execute = this.form.detailList.some(v => v.expenseFileList.some(n => !!n))
      if (execute) {
        this.loading = true
        this.$api.common
          .exportFileZip({ documentId: this.form.id })
          .then(res => {
            const blob = new Blob([res], { type: 'application/zip' })
            const fileName = `公共报销&${this.form.expenseNumber}&${this.form.id}`
            // const fileName = this.form.id
            if ('download' in document.createElement('a')) {
              // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = window.URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              window.URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
            this.loading = false
            this.$message.success('下载成功')
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else {
        this.$message.warning('当前单据没有附件可下载')
      }
    },
    rowClickFn(row) {
      this.$refs.applySelectTable.toggleRowSelection(row)
      this.registrationInfo = row
    },
    registrationIsShowFn() {
      if (this.form.registrationExpenditureId) {
        this.$api.register
          .selectDetailById(this.form.registrationExpenditureId)
          .then(res => {
            if (res.data) {
              this.oldRegistrationInfo = res.data
              this.registrationIsShow = true
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$message.warning('请选择登记单')
        this.registrationIsShow = false
      }
    },
    handleCurrentChange(val) {
      if (val.length > 1) {
        this.$refs.applySelectTable.clearSelection()
        this.$refs.applySelectTable.toggleRowSelection(val.pop())
      } else {
        this.registrationInfo = val[0]
      }
    },
    registrationFn() {
      if (this.registrationInfo.id) {
        this.loading = true
        this.$api.register
          .selectDetailById(this.registrationInfo.id)
          .then(res => {
            if (res.data) {
              let num = 0
              let arr = []
              this.oldRegistrationInfo = res.data
              if (res.data.detailList) {
                res.data.detailList.forEach(item => {
                  item.notOperable = 'NOT_OPERABLE'
                  delete item.id
                  num += item.cost
                  item.taskDate = res.data.occurDate
                  item.estimatedRefundDate = null
                  item.typeOne = null
                  item.typeTwo = null
                  item.typeThree = null
                  item.rulesTips = false
                  item.rulesTips2 = false
                  item.expenseFileList.forEach(v => {
                    delete v.id
                    v.notOperable = 'NOT_OPERABLE'
                    if (!this.isShowType(v)) {
                      this.imgList.push(v)
                    }
                  })
                })
                arr = JSON.stringify(res.data.detailList)
                delete res.data.detailList
              }
              this.form = {
                ...this.form,
                ...res.data,
                deptId: res.data.expenseBelongId,
                deptName: res.data.expenseBelongName,
                createName: res.data.applyName,
                payeeName: res.data.applyName,
                payee: res.data.applyBy,
                registrationExpenditureId: res.data.id,
                taskDate: Date.now(),
              }
              delete this.form.id
              delete this.form.status
              delete this.form.processInstId
              this.form.registrationExpenditureTotalCost = num
              this.form.expenseMode = 'REGISTRATION_EXPENDITURE_DOCUMENT'
              const newArr = JSON.parse(arr)
              this.form.detailList = []
              newArr.forEach(v => {
                this.form.detailList.push({ ...v })
              })
              // this.form.applyExpenseType = 'REGULAR_PAYMENT_REQUEST' //临时前端写死
            }
            this.loading = false
            this.registrationDialog = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.$message.warning('请选择支出登记单')
      }
    },
    cancellationFn() {
      this.form.expenseMode = 'SELF_FILING'
      this.form.detailList = [
        {
          taskDate: null,
          estimatedRefundDate: null,
          content: null,
          typeOne: null,
          typeTwo: null,
          typeThree: null,
          rulesTips: false,
          rulesTips2: false,
          cost: 0,
          contentDetail: null,
          expenseFileList: [],
        },
      ]
      this.registrationDialog = false
    },
    // 监听报销方式单选框input事件
    expenseModeChange(value) {
      if (value == 'SELF_FILING') {
        this.cancellationFn()
      } else if (value == 'REGISTRATION_EXPENDITURE_DOCUMENT') {
        this.registrationLoading = true
        this.$api.register
          .getApplyForList({
            pageNow: 1,
            pageSize: 999999,
            applyType: 'APPROVAL_PASS', //已审批
            expenseType: 'PUBLIC_SPENDING', //公共
            convertType: 'UNFINISHED', //转换状态
            registrationType: 'EXTERNAL_SPENDING', //登记单类型，只允许外部开销转换 ylz
          })
          .then(res => {
            if (res.data == []) {
              this.registrationData = res.data
            } else {
              this.registrationData = res.data ? (res.data.records ? res.data.records : []) : []
            }
            this.registrationLoading = false
            this.registrationDialog = true
          })
          .catch(err => {
            console.log(err)
            this.registrationLoading = false
            this.registrationDialog = false
          })
      }
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
        ) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },

    async printFn() {
      this.newDate = Date.now()
      this.$nextTick(async () => {
        this.examineList = []
        if (this.$refs.opinionArea && this.$refs.opinionArea.opinionRecordList.length > 1) {
          let examineArr = []
          const arr = this.$refs.opinionArea.opinionRecordList
          await arr.forEach((v, i) => {
            if (v.isCheckPass == 1) {
              v.id = i + Date.now()
              examineArr.push(v)
            }
            if (v.step == '财务支付') {
              this.cashierName = v.auditUserName
            }
          })
          if (examineArr.length == 5) {
            examineArr.unshift({
              id: '132456' + Date.now(),
              step: '财务支付',
              auditUserName: '-',
            })
          }
          let arr2 = examineArr.reduce((filteredArr, item) => {
            const index = filteredArr.findIndex(el => el.step === item.step)
            if (index !== -1) {
              const existingItem = filteredArr[index]
              if (item.auditDate > existingItem.auditDate) {
                filteredArr[index] = item
                if (item.step == '财务支付') {
                  this.cashierName = item.auditUserName
                }
              }
            } else {
              filteredArr.push(item)
            }
            return filteredArr
          }, [])
          this.examineList = arr2.reverse()
          let num = 0
          if (this.examineList.length === 0) {
            num = (this.examineList.length % 6) + 1
          } else {
            num = this.examineList.length % 6
          }
          while (num < 6 && num !== 0) {
            this.examineList.push({
              id: Date.now() + num,
              step: '',
              auditUserName: '',
            })
            num = this.examineList.length % 6
          }
          this.printDiaLog = true
        } else {
          // this.$message.warning('该报销单审核流程尚未走完')
          let num = (this.examineList.length % 6) + 1
          while (num < 6 && num !== 0) {
            this.examineList.push({
              id: Date.now() + num,
              step: '',
              auditUserName: '',
            })
            num = this.examineList.length % 6
          }
          this.printDiaLog = true
        }
      })
    },
    affirmCost(id) {
      this.pass_form = {
        affirmCostFileList: [],
        remark: '',
      }
      this.pass_form.id = id
      this.passDialogFormVisible = true
    },

    passSubmit() {
      this.loading = true
      this.$api.publicSpending
        .affirmApply(this.pass_form)
        .then(res => {
          this.loading = false
          this.$message({
            type: 'success',
            message: '成功!',
          })
          this.passDialogFormVisible = false

          this.showEditDialog = false
          this.re()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    passUpload(r) {
      r.forEach(v => {
        if (v) {
          v.type = this.type
        }
      })
      this.pass_form.affirmCostFileList.push(...r)
    },
    passDel(index) {
      this.pass_form.affirmCostFileList.splice(index, 1)
    },
    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 通过dictVal获取dictName
    getCompanyTypeName(val) {
      if (val) {
        const findResult = this.companyTypeList.find(item => item.dictVal == val)
        if (findResult) {
          return findResult.dictName
        } else {
          return ''
        }
      }
    },

    init() {
      this.getCompanyList()
      this.form = {}
      // this.tableData = [];
      this.detailType = this.options.detailType
      this.hideRejectButton = this.options.hideRejectButton ? true : false //是否展示撤回按钮
      this.showExportButton = this.options.showExportButton ? true : false
      if (this.options.id) {
        this.loading = true
        this.$api.publicSpending
          .selectDetailById(this.options.id)
          .then(res => {
            this.delBidManagementId = null
            if (res.data.bidManagement) {
              this.bidTable = [{ ...res.data.bidManagement }]
              this.bidSelectId = res.data.bidManagement.id
              this.delBidManagementId = res.data.bidManagement.id
            }
            this.form = res.data
            this.form.counteractDeleteList = res.data.counteractDeleteList
              ? res.data.counteractDeleteList
              : []
            this.form.counteractExpenseList = res.data.counteractExpenseList
              ? res.data.counteractExpenseList
              : []
            this.totalCounteract.sumAdvanceCost = this.form.sumAdvanceCost
              ? this.form.sumAdvanceCost
              : 0
            this.totalCounteract.sumCounteractCost = this.form.sumCounteractCost
              ? this.form.sumCounteractCost
              : 0
            this.isApplyExpenseType = true
            this.pitchOnList = []
            this.form.counteractExpenseList.forEach(v => {
              v.isWarning = false
              this.pitchOnList.push({
                id: v.advanceId,
                expenseNumber: v.expenseNumber,
                taskDate: v.taskDate,
                cost: v.cost,
                counteractCost: v.counteractCost,
                sumNotCounteractCost: v.sumNotCounteractCost,
              })
            })
            this.payedFileList = res.data?.affirmCostFileList
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }
            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
            if (this.$refs.form) {
              this.$refs.form.clearValidate() //清楚表单验证提示
            }
            this.loading = false
            if (
              this.options.list_s == 3 ||
              this.options.list_s == 4 ||
              this.options.export == 1 ||
              this.form.status > 5
            ) {
              this.innerArr = []
              const printData = []
              const arr = []
              const multipleArr = []
              this.form.detailList.forEach(v => {
                Promise.all([
                  this.$api.dict.listSysDictData('CBFYZC', true),
                  this.$api.dict.listSysDictData(v.typeOne, true),
                  this.$api.dict.listSysDictData(v.typeTwo, true),
                ])
                  .then(([typeOne, typeTwo, typeThree]) => {
                    const arrOne = typeOne.data.filter(item => item.dictVal == v.typeOne)
                    const arrTwo = typeTwo.data.filter(item => item.dictVal == v.typeTwo)
                    const arrThree = typeThree.data.filter(item => item.dictVal == v.typeThree)
                    v.typeOneName = arrOne[0].dictName
                    v.typeTwoName = arrTwo[0].dictName
                    v.typeThreeName = arrThree[0].dictName
                  })
                  .catch(err => {})
                arr.push(v)
                multipleArr.push(v)
              })
              let num = arr.length % 5
              while (num < 5 && num !== 0) {
                arr.push({
                  taskDate: '',
                  typeOneName: '',
                  typeTwoName: '',
                  typeThreeName: '',
                  cost: '',
                  contentDetail: '',
                })
                num = arr.length % 5
              }
              const batchSize = 5 // 每个子数组的大小
              for (let i = 0; i < arr.length; i += batchSize) {
                printData.push(arr.slice(i, i + batchSize))
              }
              // this.printIndex = this.printData.length
              let keyArr = []
              this.$api.dict.listSysDictData('CBFYZC', true).then(async res => {
                keyArr = res.data
                printData.forEach(item => {
                  let innerObj = {
                    title: '费用报销单',
                    money: '金额',
                    printData: [],
                  }
                  item.forEach(v => {
                    innerObj.amountToMoney += Number(v.cost)
                  })
                  innerObj.printData = item
                  this.innerArr.push(innerObj)
                })
                // 财务要求，只要科目一的所有明细同样即不需汇总单
                let multiple = false
                if (this.form.detailList && this.form.detailList.length > 0) {
                  multiple = multipleArr.some(v => v.typeOne !== this.form.detailList[0].typeOne)
                }
                if (printData.length > 1 && multiple) {
                  // 汇总的
                  const allArr = []
                  const temporaryArr = []
                  this.form.detailList.forEach(v => {
                    keyArr.forEach(k => {
                      if (v.typeOne === k.dictVal) {
                        allArr.push({
                          taskDate: '',
                          typeOneName: k.dictName + '(汇总)',
                          typeTwoName: '',
                          typeThreeName: '',
                          cost: v.cost,
                          contentDetail: '本次报销合计',
                        })
                      }
                    })
                  })
                  const result = []
                  for (let i = 0; i < allArr.length; i++) {
                    let found = false
                    for (let j = 0; j < result.length; j++) {
                      if (result[j].typeOneName === allArr[i].typeOneName) {
                        result[j].cost += allArr[i].cost
                        found = true
                        break
                      }
                    }

                    if (!found) {
                      result.push(allArr[i])
                    }
                  }
                  let arrNum = result.length % 5
                  while (arrNum < 5 && arrNum !== 0) {
                    result.push({
                      taskDate: '',
                      typeOneName: '',
                      typeTwoName: '',
                      typeThreeName: '',
                      cost: '',
                      contentDetail: '',
                    })
                    arrNum = result.length % 5
                  }
                  const allSize = 5 // 每个子数组的大小
                  for (let i = 0; i < result.length; i += allSize) {
                    temporaryArr.push(result.slice(i, i + allSize))
                  }
                  temporaryArr.forEach(item => {
                    this.innerArr.push({
                      title: '费用报销单(汇总)',
                      money: '合计',
                      printData: item,
                    })
                  })
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
        // this.form.applyExpenseType = 'REGULAR_PAYMENT_REQUEST' //临时前端写死
      } else {
        const str = this.userInfo.deptNames.split(',')
        this.form = {
          createName: this.userInfo.userName,
          companyType: 'hui_tu',
          deptId: this.userInfo.deptIdList[0],
          deptName: str[0],
          taskDate: new Date().getTime(),
          payee: this.userInfo.staffId,
          payeeName: this.userInfo.userName,
          applyExpenseType: 'REGULAR_PAYMENT_REQUEST',
          expenseMode: 'SELF_FILING',
          detailList: [
            {
              taskDate: null,
              estimatedRefundDate: null,
              cost: 0,
              contentDetail: null,
              typeOne: null,
              typeTwo: null,
              rulesTips: false,
              rulesTips2: false,
              typeThree: null,
              expenseFileList: [],
            },
          ],
          counteractExpenseList: [],
          counteractDeleteList: [],
        }
        this.pitchOnList = []
      }
    },

    addRedStar(h, { column }) {
      if (this.detailType == 0) {
        return [h('span', { style: 'color: red' }, '*'), h('span', ' ' + column.label)]
      } else {
        return [h('span'), h('span', column.label)]
      }
    },

    // 选择领款人
    selectDataChange(row) {
      this.form.payeeName = row?.userName
      this.form.payee = row?.id
    },

    // 监听报销方式单选框input事件
    costTypeChange(value) {
      if (value == 'APPLY_DOCUMENT') {
        // 1.隐藏添加明细按钮 2.清楚tableData里面的数据 3.显示选择报销单弹框
        this.isAddButtonShow = false
        // this.tableData = [];
        this.form.detailList = []
        this.isSelectApplyDialogShow = true
      } else if (value == 'SELF_FILING') {
        this.isAddButtonShow = true
        this.form.detailList = []
        this.form.detailList.push({
          taskDate: null,
          cost: 0,
          contentDetail: null,
        })
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    // 选择报销申请单
    applySelectSubmit() {
      if (this.multipleSelection.length != 0) {
        // 循环选中申请单
        this.multipleSelection.forEach(item => {
          if (item.detail.length != 0) {
            // 循环申请单明细
            item.detail.forEach(detailItem => {
              let obj = {
                taskDate: detailItem.taskDate,
                cost: detailItem.cost,
                contentDetail: detailItem.contentDetail,
                applyId: item.id,
              }
              this.form.detailList.push(obj)
            })
          }
        })
      }
      this.isSelectApplyDialogShow = false
    },

    // 添加一行
    addRow(index) {
      let obj = {
        taskDate: null,
        estimatedRefundDate: null,
        cost: 0,
        contentDetail: null,
        typeOne: null,
        typeTwo: null,
        typeThree: null,
        rulesTips: false,
        rulesTips2: false,
        expenseFileList: [],
      }
      if (index == 1) {
        this.form.detailList.push(obj)
      } else if (index == 2) {
        if (this.form.detailList.length == 0) {
          this.form.detailList.push(obj)
        } else {
          obj.typeOne = this.form.detailList[this.form.detailList.length - 1].typeOne
          obj.typeTwo = this.form.detailList[this.form.detailList.length - 1].typeTwo
          obj.typeThree = this.form.detailList[this.form.detailList.length - 1].typeThree
          this.form.detailList.push(obj)
        }
      }
    },

    // 删除当前行
    handleRemove(index, row) {
      if (row.id) {
        this.detailDeleteList.push(row.id)
      }
      this.form.detailList.splice(index, 1)
      row.expenseFileList.forEach(n => {
        this.imgList.forEach((v, i) => {
          if ((v.temporaryId && n.temporaryId && v.temporaryId == n.temporaryId) || v.id == n.id) {
            this.imgList.splice(i, 1)
          }
        })
      })
    },

    uploadIndex(index) {
      this.now_index = index
    },

    upload(r) {
      this.temporaryList = []
      r.forEach(v => {
        if (v) {
          v.type = this.type
          v.temporaryId = Date.now()
        }
      })
      this.form.detailList.forEach((v, i) => {
        if (this.now_index == i) {
          if (!v.expenseFileList) {
            v.expenseFileList = []
          }
          v.expenseFileList.push(...r)
        }
      })
      this.temporaryList.push(...r)
    },

    del(index, now_index, n) {
      //index 表示当前点击删除按钮的下标，now_index表示当前行的下标

      this.form.detailList.forEach((v, key) => {
        if (key == now_index) {
          v.expenseFileList.forEach((k, i) => {
            if (i == index) {
              if (k.id) {
                this.fileDeleteList.push(k.id)
              }
              v.expenseFileList.splice(i, 1)
            }
          })
        }
      })
      this.imgList.forEach((item, index) => {
        if (
          (item.temporaryId && n.temporaryId && item.temporaryId == n.temporaryId) ||
          item.id == n.id
        ) {
          this.imgList.splice(index, 1)
        }
      })
    },
    invoiceUpload(r) {
      this.upload(r)
    },

    valueChange(value, row, index) {
      //value表示数据对象返回值，row表示当行内容，index是手动传入
      if (index == 1) {
        //  科目一发生变动，清空科目二科目三内容
        row.rulesTips = false
        row.typeThree = null
        row.typeTwo = null
        row.rulesTips2 = false
        row.estimatedRefundDate = null
      }
      if (index == 2 && row.rulesTips) {
        if (value && row.typeTwo) {
          row.rulesTips = false
        }
      } else if (index == 3 && row.rulesTips2) {
        if (value && row.typeThree) {
          row.rulesTips2 = false
        }
      }
      if (index == 2 && value) {
        // 科目二更换内容
        row.typeThree = null
        row.rulesTips2 = false
      } else if (index == 3 && value) {
        //科目三选中后清除校验
        row.rulesTips2 = false
      }
      this.$forceUpdate()
    },

    requiredTwo(dictList, row, index) {
      if (dictList.length > 0) {
        if (index == 2) {
          row.rulesTips = true
        } else if (index == 3) {
          row.rulesTips2 = true
        }
      } else {
        if (index == 2) {
          row.rulesTips = false
        } else if (index == 3) {
          row.rulesTips2 = false
        }
      }
    },

    // 提交
    save() {
      if (this.bidTable && this.bidTable.length > 0) {
        // 新增
        if (this.bidIsShow) {
          this.form.bidManagementId = this.bidTable[0].id
        }
        // 有id
        if (this.delBidManagementId) {
          // 没有更换投标
          if (this.delBidManagementId === this.bidTable[0].id) {
            this.form.delBidManagementId = null
          }
          // 编辑更换投标
          if (this.form.bidManagement.id !== this.bidTable[0].id) {
            this.form.bidManagementId = this.bidTable[0].id
            this.form.delBidManagementId = this.delBidManagementId
          }
        }
        // 关联后移除
        if (!this.bidIsShow) {
          this.form.delBidManagementId = this.bidTable[0].id
        }
      }
      // 有id 并且再移除关联
      if (this.delBidManagementId && this.bidTable.length <= 0) {
        this.form.delBidManagementId = this.delBidManagementId
      }
      this.status = 1
      if (this.bidIsShow && this.bidTable.length <= 0) {
        return this.$message.warning('请关联投标信息')
      }
      let ruleTips = false
      this.form.detailList.forEach(m => {
        if (!m.typeOne || m.typeOne == 'undefined') {
          ruleTips = true
        }
        //验证
        if (!m.taskDate || m.taskDate == null) {
          ruleTips = true
        }
        if (
          (!m.estimatedRefundDate && m.typeTwo === 'tou_bao_zheng_jin') ||
          (!m.estimatedRefundDate && m.typeTwo === 'lv_yue_bao_zheng_jin')
        ) {
          ruleTips = true
        }
        if (!m.cost || m.cost <= 0) {
          ruleTips = true
        }
        if (!m.contentDetail || m.contentDetail == null) {
          ruleTips = true
        }
        if ((m.typeTwo == '' || m.typeTwo == 'undefined' || m.typeTwo == null) && m.rulesTips) {
          ruleTips = true
        }
        if (
          (m.typeThree == '' || m.typeThree == 'undefined' || m.typeThree == null) &&
          m.rulesTips2
        ) {
          ruleTips = true
        }
        if (m.rulesTips) {
          this.rulesTips = 1
        } else {
          this.rulesTips = 0
        }
        if (m.rulesTips2) {
          this.rulesTips2 = 1
        } else {
          this.rulesTips2 = 0
        }
        //判断科目类型是否为交通费或住宿费
        this.isSaveTipsShow = this.judgeIsTrafficOrStay(m)
      })
      if (ruleTips) {
        ++this.tableIndex
        this.$forceUpdate()
        return
      }
      let amount = this.amount
      if (amount <= 0) {
        this.$message('报销总金额不能为0')
        return
      }

      //经费单冲报销单校验
      if (this.form.counteractExpenseList.find(v => v.isWarning)) return
      if (this.form.counteractExpenseList.find(v => !Number(v.counteractCost))) return
      if (
        this.form.applyExpenseType == 'ADVANCE_PAYMENT' &&
        this.form.counteractExpenseList.length <= 0
      ) {
        this.$message.warning('请添加经费预支单或选择正常付款申请')
        return
      }
      if (this.form.applyExpenseType == 'ADVANCE_PAYMENT' && this.thisTimeMoney > this.amount) {
        this.$message.warning('本单报销金额要大于或等于冲还金额')
        return
      }
      if (
        this.form.applyExpenseType == 'ADVANCE_PAYMENT' &&
        this.form.payee != this.userInfo.staffId
      ) {
        this.$message.warning('冲还预付款报销领款人必须是申请人')
        return
      }
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.loading = true
          this.submitDialog = true
          this.loading = false
        } else {
          this.scrollView(obj)
        }
      })
    },
    // 提交意见
    submitOpinionFn(opinion) {
      //默认获取当天
      let cond = {
        ...this.form,
        taskDate: new Date().getTime(),
        detailDeleteList: this.detailDeleteList,
        fileDeleteList: this.fileDeleteList,
        opinion,
      }
      this.loading = true
      // 判断当前单据1状态是否为0且包含流程id，是的话说明该单据是撤回的，走重新提交接口
      if (
        (this.form.status == 0 && this.form.processInstId) ||
        (this.form.status == 5 && this.form.processInstId)
      ) {
        this.$api.publicSpending
          .resubmitByReject(cond)
          .then(res => {
            this.loading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      } else {
        this.$api.publicSpending
          .startProcess(cond)
          .then(res => {
            this.loading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      }
      this.submitDialog = false
    },

    //* 暂存
    tempSave() {
      //经费单冲报销单校验
      if (this.form.counteractExpenseList.find(v => v.isWarning)) return
      if (this.form.counteractExpenseList.find(v => !Number(v.counteractCost))) return
      if (
        this.form.applyExpenseType == 'ADVANCE_PAYMENT' &&
        this.form.counteractExpenseList.length <= 0
      ) {
        this.$message.warning('请添加经费预支单或选择正常付款申请')
        return
      }
      if (this.form.applyExpenseType == 'ADVANCE_PAYMENT' && this.thisTimeMoney > this.amount) {
        this.$message.warning('本单报销金额要大于或等于冲还金额')
        return
      }
      if (
        this.form.applyExpenseType == 'ADVANCE_PAYMENT' &&
        this.form.payee != this.userInfo.staffId
      ) {
        this.$message.warning('冲还预付款报销领款人必须是申请人')
        return
      }
      if (this.bidTable && this.bidTable.length > 0) {
        // 新增
        if (this.bidIsShow) {
          this.form.bidManagementId = this.bidTable[0].id
        }
        // 有id
        if (this.delBidManagementId) {
          // 没有更换投标
          if (this.delBidManagementId === this.bidTable[0].id) {
            this.form.delBidManagementId = null
          }
          // 编辑更换投标
          if (this.form.bidManagement.id !== this.bidTable[0].id) {
            this.form.bidManagementId = this.bidTable[0].id
            this.form.delBidManagementId = this.delBidManagementId
          }
        }
        // 关联后移除
        if (!this.bidIsShow) {
          this.form.delBidManagementId = this.bidTable[0].id
        }
      }
      // 有id 并且再移除关联
      if (this.delBidManagementId && this.bidTable.length <= 0) {
        this.form.delBidManagementId = this.delBidManagementId
      }
      //默认获取当天
      let cond = {
        ...this.form,
        taskDate: new Date().getTime(),
        detailDeleteList: this.detailDeleteList,
        fileDeleteList: this.fileDeleteList,
      }
      this.loading = true
      //* 如果有id且当前状态是暂存，则走暂存编辑接口
      if (this.form.id) {
        //todo 被删除的明细如何提交
        this.$api.publicSpending
          .undeterminedEdit(cond)
          .then(res => {
            this.loading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      } else {
        this.$api.publicSpending
          .tempAdd(cond)
          .then(res => {
            this.loading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      }
    },

    showDialogForm(status) {
      this.dg_form.opinion = ''
      this.dg_form.status = status
      this.dialogFormVisible = true
    },

    // 审核
    submit() {
      let query = {
        id: this.form.id,
        opinion: this.dg_form.opinion,
      }
      // 点击通过
      if (this.dg_form.status == 1) {
        query.opinion = !query.opinion.trim() ? '通过' : query.opinion
        query.processInstId = this.form.processInstId
        this.loading = true
        this.$api.addAuditor
          .disposeTask(query)
          .then(res => {
            this.loading = false
            this.dialogFormVisible = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else if (this.dg_form.status == 2) {
        // todo 审批不通过
        this.loading = true
        this.$api.publicSpending
          .approvalReject(query)
          .then(res => {
            this.loading = false
            this.dialogFormVisible = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      }
    },

    // 撤回
    reject() {
      let cond = {
        id: this.form.id,
      }
      this.loading = true
      this.$api.publicSpending
        .rejectExpense(cond)
        .then(res => {
          this.loading = false
          this.showEditDialog = false
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          this.loading = false
          console.log('错误：', err)
        })
    },

    exporting() {
      let time = new Date(this.$refs.opinionArea?.opinionRecordList[0].auditDate).format(
        'yyyy-MM-dd'
      )
      let type = this.$refs.opinionArea?.opinionRecordList[0].type
      let str = `${type}&${time}&${this.form.createName}&${this.amount}元`
      this.$api.publicSpending
        .exporting(this.options.id)
        .then(res => {
          const fileName = `${str}.xlsx`
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }

          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {})
    },

    handleClose() {
      this.dialogFormVisible = false
      this.$refs.dg_formRef?.resetFields()
      this.dg_form.opinion = ''
    },

    addApprove() {
      this.$refs.dg_formRef.validate(valid => {
        if (valid) {
          if (this.dg_form.status == 1) {
            if (this.dg_form.opinion == '') {
              this.dg_form.opinion = '通过'
            }
          }
          this.submit()
        }
      })
    },

    judgeIsTrafficOrStay(m) {
      if (m.typeTwo) {
        // 存在费用科目二
        if (
          m.typeTwo == 'jiao_tong_fei' ||
          m.typeTwo == 'zhu_su_fei' ||
          m.typeTwo == 'zhu_su_fei_（_zhao_dai_）' ||
          m.typeTwo == 'jiao_tong_fei_（_zhao_dai_）'
        ) {
          // 且一旦费用科目二选定了以上几项那么返回true
          return true
        }
        return false
      }
      return false
    },

    handleChangeLoading(e) {
      this.typeDialogLoading = e
    },
    getType() {
      this.$api.sysDept
        .queryStaffDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('REGISTRATION_TYPE', true)
        .then(res => {
          this.registrationTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('APPLY_EXPENSE_TYPE', true)
        .then(res => {
          this.applyExpenseType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BID_STATUS', true)
        .then(res => {
          this.bidStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BID_TYPE', true)
        .then(res => {
          this.bidType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return ''
      }
    },
    amountToConvert(n) {
      if (!n) return n
      n = Number(n).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return n
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
#applySelectTable {
  ::v-deep .el-table__header {
    .el-checkbox {
      display: none;
    }
  }
}

/deep/.businessOutSource {
  //费用类别背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}
.addButton {
  margin-bottom: 10px;
}

table {
  width: 100%;
}
td {
  width: 25vh;
}

b {
  font-size: 19px;
  color: black;
}

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}

.bg_div {
  border: 1px solid #eee;
  margin-bottom: 10px;
}

.color_r {
  color: #f00;
}

.row_css {
  font-size: 14px;
  color: #909399;
  vertical-align: inherit;
  padding-left: 5px;
}

/deep/ .el-table .cell {
  white-space: pre-line;
  width: 100% !important;
}

.upload-center {
  display: flex;
  justify-content: center;
}

.el-form-item {
  margin-bottom: 0px !important;
}

/deep/.form-item-width {
  width: 300px !important;
}
.expand-block {
  width: 100%;
  padding: 20px 50px;

  .expand-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
#printArea {
  font-family: '微软雅黑';
  width: 1000px;
}
.outer-table {
  display: none;
  text-align: right;
  font-size: 16px !important;
}
#printArea table {
  word-break: break-all !important;
  font-size: 12px !important;
  .detail {
    text-align: center;
    height: var(--height); //75
    line-height: 1;
  }
  thead {
    tr {
      height: 40px;
    }
  }
  tfoot {
    text-align: center;
    tr {
      height: 40px; //40
    }
  }
}
@media print {
  @page {
    margin: 8mm 10mm;
    size: landscape; //横向
  }

  tbody {
    page-break-inside: avoid; /* 避免将tbody分割在多个页面上 */
    //   /* 根据需要在第四条数据之后进行分页处理 */
  }
  .outer-table {
    display: block;
  }
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.spendingLabelClassName {
  width: 16.6%;
}
/deep/.spendingContentClassName {
  height: 75px !important;
}
.iconCostDelete {
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
// 附件编码
.invoice-file {
  display: flex;
  margin-top: 5px;
  .invoice-file-left {
    width: 85%;
  }
}
/deep/.headerInvoiceClassName {
  background-color: #b2f3fe !important;
}
</style>
